import hljs from 'highlight.js/lib/core';
import bashLang from 'highlight.js/lib/languages/bash';
import httpLang from 'highlight.js/lib/languages/http';
import jsLang from 'highlight.js/lib/languages/javascript';
import jsonLang from 'highlight.js/lib/languages/json';
import phpLang from 'highlight.js/lib/languages/php';
import scssLang from 'highlight.js/lib/languages/scss';
import shellLang from 'highlight.js/lib/languages/shell';
import sqlLang from 'highlight.js/lib/languages/sql';
import twig from 'highlight.js/lib/languages/twig';
import yaml from 'highlight.js/lib/languages/yaml';
import 'highlight.js/styles/agate.css';

hljs.registerLanguage('bash', bashLang);
hljs.registerLanguage('http', httpLang);
hljs.registerLanguage('javascript', jsLang);
hljs.registerLanguage('json', jsonLang);
hljs.registerLanguage('php', phpLang);
hljs.registerLanguage('scss', scssLang);
hljs.registerLanguage('shell', shellLang);
hljs.registerLanguage('sql', sqlLang);
hljs.registerLanguage('twig', twig);
hljs.registerLanguage('yaml', yaml);
hljs.highlightAll();
