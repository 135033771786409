import { Tooltip, Toast } from 'bootstrap';

var toastElList = [].slice.call(document.querySelectorAll('.toast'));
toastElList.map(function (toastEl) {
  var toast = new Toast(toastEl, { delay: 4000 });
  toast.show();
});

var tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-toggle="tooltip"]')
);
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl);
});
